import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useError } from '../../../../../../../../../hooks/UseError'
import { PeriodAdjustmentField, LevelDropdown } from './commonComponents';
import { IntegrationEnable } from '../../../../../../../../recons/IntegrationEnable';
import { INTEGRATION_SOURCE_TYPE } from '../../../../../../../../../constants/integration_source_type';
import { getCSRF } from '../../../../../../../../../helpers/helpers';

interface QBOPopperProps {
  task: any;
  sourceType: any;
  state: any;
  setState: any;
  setDisableButton: any;
}

interface Option {
  label: string;
  value: string;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

const LEVELS: IntegrationField[] = [
  { label: 'QBO Integration', value: 'qboIntegration', isRequired: true },
  { label: 'Account', value: 'accounts', isRequired: true, isMulti: true },
  { label: 'Name', value: 'names', isMulti: true },
  { label: 'Class', value: 'classes', isMulti: true },
  { label: 'Location', value: 'locations', isMulti: true },
  { label: 'Transaction type', value: 'types' },
  { label: 'Accounting method', value: 'accountingMethod', options: [{ label: 'Cash', value: 'Cash' }, { label: 'Accrual', value: 'Accrual' }] },
  { label: 'Period', component: PeriodAdjustmentField }
];


export function QBOPopper({
  sourceType, task, state, setState, setDisableButton
}: QBOPopperProps
) {
  const showError = useError();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [OPTIONS, SETOPTIONS] = useState<any>({});
  //determine if the user can edit the recon settings or not
  const currentUserIsOwner = task.list.currentUserIsOwner;
  const [integrationOptions, setIntegrationOptions] = useState<Option[]>([]);

  useEffect(() => {
    axios.get('/connector/qbo/integrations')
      .then(response => {
        const integrationList = response.data.integrations;
        if (Array.isArray(integrationList)) {
          const updatedOptions = integrationList.map((i: any) => ({ label: i.name, value: String(i.id) }));
          setIntegrationOptions(updatedOptions);

          // If state.qboIntegration is not set, set it to the first integration in the list
          if (!state.qboIntegration && updatedOptions.length > 0) {
            setState({ ...state, qboIntegration: updatedOptions[0] });
          }
        } else {
          console.error("Expected an array but received:", integrationList);
        }
      })
      .catch(error => {
          console.error("Error fetching integrations:", error);
      });
  }, []);

  /** Get the menu options at the first load. */
  useEffect(() => {

    const newCancelToken = axios.CancelToken.source();
    setDisableButton(true);
    axios.get('/connector/qbo/menuitems', {
      headers: { 'X-CSRF-TOKEN': getCSRF() },
      cancelToken: newCancelToken.token,
      params: {
        task_id: task.id,
        integration_id: state.qboIntegration?.value
      }
    }).then((a: any) => {
      setShowSpinner(false);
      SETOPTIONS(a.data);

      // if `a.data.accounts` is empty, then we should disable the button
      if (a.data.accounts.length === 0) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }).catch((e: any) => {
      setDisableButton(false);
      //Do nothing if we cancelled the calle
      if (axios.isCancel(e)) {
        return;
      }

      showError('There is a problem with your company\'s QuickBooks connection, please contact your company\'s administrator or PrepDD support for assistance.');
      setShowSpinner(false)
    });

    return () => {
      newCancelToken.cancel(
        "Component unmounted, cancelling request"
      )
    }
  }, [state.qboIntegration]);

  const launchQBOAuthenticate = () => {
    sessionStorage.setItem('connectList', task.list.id)
    axios.get('/connector/qbo/connect', {
      params: {
        list_id: task.list.id,
        add_new_integration: true
      }
    })
      .then((d: any) => window.location = d.data.url)
    setState((prevState: any) => ({ ...prevState, showQBOEnable: false }));
  }

  const closeQBOModals = () => {
    setState((prevState: any) => ({ ...prevState, showQBOEnable: false }));
  }

  return (
    <>
      <IntegrationEnable
        open={state.showQBOEnable}
        denyAction={closeQBOModals}
        integrationType={'' + INTEGRATION_SOURCE_TYPE.QBO}
        confirmAction={launchQBOAuthenticate}
      />
      
      <div>
        <div style={{ minWidth: 440, height: 200, overflow: 'auto', display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px' }}>
          {
            LEVELS.map((level: any, i: number) => {
              const options = 
                (level.value === 'qboIntegration' && integrationOptions.length > 0) ? integrationOptions :
                (OPTIONS[level.value]?.map((o: any) => o.id ? { value: o.id, label: o.name } : { value: o, label: o }) || level.options);
              
              return (
                <LevelDropdown
                  i={i}
                  key={i}
                  level={level}
                  options={options}
                  set={(x: any) => {
                    setState({ ...state, [level.value]: x })
                  }}
                  state={state}
                  setState={setState}
                  showSpinner={showSpinner}
                  currentUserIsOwner={currentUserIsOwner}
                  v={state[level.value] || level.label === 'Period'} 
                  startDateParamName="qboStartDate"
                  endDateParamName="qboEndDate"
                  integrationType="QBO"
                  dimension={OPTIONS[level.value]}
                  isRequired={level.isRequired}
                />
              )
            })
          }
        </div>
      </div>
    </>
  )
}