import {createMutationHook, gql} from '../graphqlHelpers';

const createPrivateTaskMessage = createMutationHook(gql`
  mutation CreatePrivateTaskMessage($taskId: ID!, $message: String!, $selectedOwners: [TaggingOwnerAttributes!], $messageParentId: ID, $messageLabelTypes: [MessageLabelTypeAttributes!]) {
    createTaskMessages(taskId: $taskId, message: $message, isPublic: false, selectedOwners: $selectedOwners, messageParentId: $messageParentId, messageLabelTypes: $messageLabelTypes) {
      success
      message {
        id
        message
        createdAt
        task {
          id
          parentId
          position
        }
        user {
          id
          fullName
          profileUrl
        }
        resolveDate
        resolveUser {
          fullName
          profileUrl
        }
        resolveStatusOption {
          id
          description
          resolveObject {
            id
            description
          }
        }
        resolveStatusDescription
        isEdited
        labels {
          id
          name
          color
        }
        childMessages {
          id
        }
      }
    }
  }
`);

export default createPrivateTaskMessage;
