import { useLazyQuery, gql } from "@apollo/client"

const ListUserFragment = gql`
  fragment ListUserFragment on List {
    id
    reconciliations
    companyPosition
    deliverFileOnUpload
    currentUserIsOwner
    isArchived
    isDeleted
    rank
    name
    taggingUsers {
      ... on User {
        id
        email
        fullName
        profileUrl
      }
      ... on Team {
        id
        name
      }
    }
    sections {
      id
      name
      position
    }
    labels {
      id
      name
      color
    }
  }
`;

const userListsQuery = gql`
  query UserLists {
    userLists {
      id
      lists {
        ...ListUserFragment
      }
    }
  }
  ${ListUserFragment}
`

const userListQuery = gql`
  query UserList($id: ID!) {
    list(id: $id) {
      ...ListUserFragment
    }
  }
  ${ListUserFragment}
`

export const useLazyTaskPageUserLists = () => {
  return useLazyQuery(userListsQuery)
}

export const useLazyTaskPageUserList = () => {
  return useLazyQuery(userListQuery)
}

