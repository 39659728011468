import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCSRF } from '../../../../../../../../../helpers/helpers';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useError } from '../../../../../../../../../hooks/UseError'
import { PeriodAdjustmentField, LevelDropdown } from './commonComponents';

import {
  disableOption,
  ACCOUNT_METHOD_OPTIONS,
  STATISTICAL_OPTIONS,
} from '../../../../../../../../../constants/dimensions';
import { useLazyAdjBookQuery } from '../../../../../../../../../graphql/queries/AdjBook';

// 📍 This component logic is duplicated.
// TODO: Find a time to create a common reusable component.
interface IntacctPopperProps {
  task: any;
  sourceType: any;
  state: any;
  setState: any;
  handleInvert: any;
  setDisableButton: any;
}

interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

const LEVELS: IntegrationField[] = [
  { label: 'Entity', value: 'inEntities', isRequired: false, isMulti: true },
  { label: 'Department', value: 'inDepartments', isMulti: true },
  { label: 'Class', value: 'inUnderliers', isMulti: true },
  { label: 'GL Account', value: 'inAccounts', isMulti: true, isRequired: true },
  { label: 'Item', value: 'inServices', isMulti: true },
  { label: 'Vendor', value: 'inVendors', isMulti: true },
  { label: 'Accounting method', value: 'inAccountingMethod',
    options: [
      { label: 'Cash', value: 'CASH', isDisabled: false },
      { label: 'Accrual', value: 'ACCRUAL', isDisabled: false },
      { label: 'Include Statistical Accounts', value: 'Include', isDisabled: false },
      { label: 'Exclude Statistical Accounts', value: 'Exclude', isDisabled: false },
    ],
    isMulti: true
  },
  { label: 'Period', component: PeriodAdjustmentField, isMulti: false }
];

export function IntacctPopper({
 task, state, setState, handleInvert, setDisableButton
}: IntacctPopperProps
) {
  const [books, setBooks] = useState<any>({});
  const showError = useError();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [OPTIONS, SETOPTIONS] = useState<any>({});
  //determine if the user can edit the recon settings or not
  const currentUserIsOwner = task.list.currentUserIsOwner;
  
  // Fetch the current task account AdjBooks
  const { loading, data, error } = useLazyAdjBookQuery({ taskId: task.id });

  useEffect(() => {
    if (data) {
      setBooks(data)
    }
  }, [data]);

  const optimistic = books.adjBook && books.adjBook.map((adj: any) => ({ 'adjbook' : adj.name }));

  const companyAdjBooks = (optimistic && optimistic.length > 0) ? optimistic : [{adjbook : 'GAAP'}, {adjbook : 'TAX'}];

  /** Get the menu options at the first load. */
  useEffect(() => {

    const newCancelToken = axios.CancelToken.source();

    setDisableButton(true);
    axios.get('/connector/intacct/menuitems', {
      headers: { 'X-CSRF-TOKEN': getCSRF() },
      cancelToken: newCancelToken.token
    }).then((a: any) => {
      setShowSpinner(false);
      for (let i = 0; i < a.data.inAccounts.length; ++i) {
        a.data.inAccounts[i] = a.data.inAccounts[i]
        a.data.inAccounts[i].name = a.data.inAccounts[i].id.toString() + ' - ' + a.data.inAccounts[i].name.toString()
      }
      SETOPTIONS(a.data);
      // if `a.data.inAccounts` is empty, then we should disable the button
      if (a.data.inAccounts.length === 0) {
        setDisableButton(true);
        
      } else {
        setDisableButton(false);
      }
    }).catch((e: any) => {
      setDisableButton(false);
      //Do nothing if we cancelled the calle
      if(axios.isCancel(e)){
        return;
      }

      showError('There is a problem with your company\'s Intacct connection, please contact your company\'s administrator or PrepDD support for assistance.');
      setShowSpinner(false)
    });

    return () => {
      newCancelToken.cancel(
        "Component unmounted, cancelling request"
      )
    }
  }, []);

  // Only allow the combination for 1 reportingbook + 1 or many adjbook + 1 statistical.
  // For ReportingBook, either "CASH" or "ACCRUAL" can be selected.
  // For Adjbooks, both "GAAP" and "TAX" can be selected.
  // For Statistical, either "Include" or "Exclude" can be selected
  const availableDimensions = LEVELS.slice(0).map((dimension: any) => {
    if (dimension.value === 'inAccountingMethod') {
      const selectedOption = state['inAccountingMethod'];
      const selectedValues = selectedOption.map((opt: any) => opt.value);
      const allOptions = dimension.options.slice(0);

      const optimistic = companyAdjBooks.map((adj: any) => ({'label': adj.adjbook, 'value': adj.adjbook, 'isDisabled': false}));
      const kudoOptions = allOptions.concat(optimistic);

      for (let j = 0; j < dimension.options.length; j++) {
        kudoOptions[j].isDisabled = false;
        const prevValue = dimension.options[j].value;

        if (ACCOUNT_METHOD_OPTIONS.includes(prevValue) ) {
          disableOption(j, dimension, selectedValues, ACCOUNT_METHOD_OPTIONS);
        } else if (STATISTICAL_OPTIONS.includes(prevValue) ) {
          disableOption(j, dimension, selectedValues, STATISTICAL_OPTIONS);
        }
      }

      dimension = Object.assign({}, {
        ...dimension,
        options: dimension.options.concat(optimistic)
      });
    }
    return {...dimension};
  });

  return (
    <div>
      <div className='min-w-[440px] h-[200px] py-3.5 flex flex-col overflow-auto'>
        {availableDimensions.map((level: any, i: number) =>
          <LevelDropdown
            i={i}
            key={i}
            level={level}
            options={OPTIONS[level.value] && OPTIONS[level.value].map((o: any) => o.id
              ? { value: o.id, label: o.name }
              : { value: o, label: o }) || level.options}
            set={(x: any) => {
              setState({ ...state, [level.value]: x })
            }}
            state={state}
            setState={setState}
            showSpinner={showSpinner}
            currentUserIsOwner={currentUserIsOwner}
            v={state[level.value] || level.label === 'Period'}
            startDateParamName="inStartDate"
            endDateParamName="inEndDate"
            integrationType="Intacct"
            dimension={OPTIONS[level.value]}
            isRequired={level.isRequired}
          />
        )}

        <div className='flex flex-row'>
          <Typography className='w-[140px] !pt-3'>
            Other Options:
          </Typography>
          <div className='flex flex-col ml-7'>
            <div className='flex items-center'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.invert}
                    color='primary'
                    onChange={handleInvert}
                    value={state.invert}
                    disabled={!currentUserIsOwner}
                  />
                }
                label={<Typography className='!cursor-pointer'>Invert total(s)</Typography>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}