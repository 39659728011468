import React, { useEffect, useState } from "react";
import { DatePicker } from "../../../../../../../../common/DatePicker";
import { Grid, Switch, Typography, withStyles } from "@material-ui/core";
import { components } from 'react-select';
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import { useGlobalStyles } from '../../../../../../../../common/Style';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from 'react-select';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import { NEW_INTEGRATION_OPTION } from "../../../../../../../../../constants/dimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    periodAdjustmentField: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    dateRowContainer: {
      display: 'flex',
      alignItems: 'space-between',
      alignContent: 'center'
    },
    datePicker: {
      marginRight: '15px'
    },
    datePickerDisabled: {
      backgroundColor: 'lightgray'
    }
  })
);

const datePickerOnChange = (date: MaterialUiPickersDate, dateParamName: string, { setState, state }: any, integrationType: string) => {
  //If date is invalid, we don't want to update the state
  if (date && date.toString() !== "Invalid Date") {
    let formattedDate;
    if (integrationType === 'Intacct') {
      formattedDate = formateDateForIntacct(date);
    } else if (integrationType === 'QBO') {
      formattedDate = formatDateForQBO(date);
    } else {
      formattedDate = date;
    }

    setState({ ...state, [`${dateParamName}`]: formattedDate });
  }
}

const formatDateForQBO = (date: MaterialUiPickersDate) => {
  if (date) {
    const formattedDate = [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-');

    return formattedDate;
  }
}

const formateDateForIntacct = (date: MaterialUiPickersDate) => {
  if (date) {
    const formattedDate = date.toDateString();
    return formattedDate;
  }
}

//Need to formate date to prevent Timezone issues with the MUI Date Picker
const formatDateForDatePicker = (date: Date | string, integrationType: string) => {
  if (typeof date === 'object') {
    return date.toDateString();
  } else {
    //This check is here for legacy dates that are still in ISO format
    if(date.includes('Z')){
      return date.substr(0,10).replace(/-/g, '/');
    } else {
      if (integrationType === 'Intacct') {
        return date; //Already formatted as date.toDateString()
      } else {
      /**
       * QBO Date strings are stored in (YYYY-MM-DD) format
       * Attaching discussing why we need to change the format
       * Source: https://github.com/mui-org/material-ui-x/issues/1573#issuecomment-834540873
       * TLDR: JS Date's converts (YYYY-MM-DD) to UTC time and converts (YYYY/MM/DD) to local time
       */
        return date.replace(/-/g, '/'); //Needs formatted to 
      }
    }
  }
}

export function PeriodAdjustmentField({ setState, state, startDateParamName, endDateParamName, key, integrationType, currentUserIsOwner }: any) {

  const startDate = formatDateForDatePicker(state[startDateParamName], integrationType);
  const endDate = formatDateForDatePicker(state[endDateParamName], integrationType);

  const classes = useStyles();
  return (
    <div key={`periodAdjustmentField${key}`} className={classes.periodAdjustmentField}>
      <div className={classes.dateRowContainer}>
        <DatePicker
          onChange={(d: any) => datePickerOnChange(d, startDateParamName, { setState, state }, integrationType)}
          value={startDate}
          classes={clsx(classes.datePicker, !currentUserIsOwner && classes.datePickerDisabled)}
          disabled={!currentUserIsOwner}
        />
        <LockSwitchToggle name="startDateLockSwitch" locked={state.startDateLocked} handleChange={() => { setState({ ...state, startDateLocked: !state.startDateLocked }) }} disabled={!currentUserIsOwner} />
      </div>
      <Typography style={{ width: 130, textAlign: "center" }} component="span">to</Typography>
      <div className={classes.dateRowContainer}>
        <DatePicker
          onChange={(d: any) => datePickerOnChange(d, endDateParamName, { setState, state }, integrationType)}
          value={endDate}
          classes={clsx(classes.datePicker, !currentUserIsOwner && classes.datePickerDisabled)}
          disabled={!currentUserIsOwner}
        />
        <LockSwitchToggle name="endDateLockSwitch" locked={state.endDateLocked} handleChange={() => { setState({ ...state, endDateLocked: !state.endDateLocked }) }} disabled={!currentUserIsOwner} />
      </div>
    </div>
  );
}

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
};


const selectStyles: any = (props: any) => ({
  control: (provided: any, state: any) => ({
    alignItems: "center",
    border: props.isRequired && (Array.isArray(props.v) ? props.v?.length === 0 : !props.v) ? "1px solid red" : "1px solid hsl(0,0%,80%)",
    borderRadius: "4px",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
    background: state.isDisabled ? 'lightgrey' : '#FFF'
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menu: (provided: any) => ({
    ...provided,
    border: "1px solid hsl(0,0%,80%)",
    boxShadow: "none",
    marginTop: -1
  }),
  multiValue: () => ({
    background: "#FFF",
    border: "1px solid hsl(0,0%,80%)",
    borderRadius: "2px",
    display: "flex",
    margin: "2px",
    padding: "2px",
    minWidth: 0,
    boxSizing: "border-box",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: "#FFF",
    color: "lightgrey",
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: "center",
    display: "flex",
  }),
});

const disableSelectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    backgroundColor: 'hsl(0deg 0% 80% / 44%)'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1
  }),
}

// This component is redefined in the IntacctValueSelection component.
// It may be the case for NetSuit and QBO.
// TODO: Find a time to replace the reusable component and remove duplications.
export function LevelDropdown(props: any) {
  const classes = useGlobalStyles();
  const { state, setState, dimension } = props;

  // props.v can be a string || an array
  const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length);

  const levelDropdownOptions = (props.integrationType === 'QBO' && props.level?.value === 'qboIntegration') ?
    [...(props.options || []), NEW_INTEGRATION_OPTION] :
    props.options || [];

  function handleOnChange(e: any) {
    if(e.value === 'ADD_NEW_INTEGRATION'){
      setState((prevState: any) => ({ ...prevState, showQBOEnable: true }));
    } else {
      props.set(e);
    }
  }
  
  return (
    <div className={classes.flex} style={{ marginBottom: 10 }}>
      <div
        className={classes.flexCenter}
        style={{ justifyContent: "flex-start" }}
      >
        <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
          <div style={{ width: 20, marginRight: 10 }}>
            {props.showSpinner && !emptyProps ? (
              <CircularProgress size={"0.75rem"} />
            ) : (dimension && dimension.length === 0)
             ? <CancelIcon style={{ color: 'green', opacity: 0.1 }}/>
             : (
              <CircleCheckedFilled
                style={{
                  height: "0.70em",
                  width: "0.70em",
                  color: "green",
                  opacity: props.v ? 1 : 0.1,
                }}
              />
            )}
          </div>
          <Typography style={{ width: 140 }}>{props.level.label}</Typography>
        </div>
        <div style={{ width: 250 }} className={classes.dropdownContainer}>
          {props.level.component ? (
            props.level.component({
              state,
              setState,
              startDateParamName: props.startDateParamName,
              endDateParamName: props.endDateParamName,
              integrationType: props.integrationType,
              currentUserIsOwner: props.currentUserIsOwner
            })
          ) : (
            <Select
              components={{ DropdownIndicator }}
              options={levelDropdownOptions}
              isClearable={false}
              isMulti={props.level.isMulti}
              styles={ dimension && dimension.length === 0 ? disableSelectStyles : selectStyles(props)}
              onChange={(e: any) => handleOnChange(e)}
              value={props.v}
              isDisabled={!props.currentUserIsOwner || dimension && dimension.length === 0}
            />
          )}
        </div>
      </div>
    </div>
  );
}


interface ILockSwitchProps {
  locked: boolean,
  name: string,
  disabled?: boolean,
  handleChange: () => void
}


export function LockSwitchToggle(lockSwitchProps: ILockSwitchProps) {

  const { locked, name, disabled, handleChange } = lockSwitchProps;

  return (
    <Typography component='div' style={{ paddingTop: '6px', paddingBottom: '6px' }}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <LockSwitch checked={locked} onChange={handleChange} name={name} disabled={disabled} />
        </Grid>
        <Grid item>{locked ? 'Locked' : 'Unlocked'}</Grid>
      </Grid>
    </Typography>
  )
}

const LockSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 0,
      color: theme.palette.grey[500],
      '&:hover': {
        border: '2px solid transparent !important'
      },
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);