import {gql} from '../graphqlHelpers';
import { useLazyQuery } from '@apollo/client';

const listSectionsQuery = gql`
	query ListSections($id: ID!) {
		list(id: $id) {
			sections {
				id
				name
				position
			}
		}
	}
`;

const useLazyListSections = () => {
	return useLazyQuery(listSectionsQuery, {
		fetchPolicy: 'network-only'
	});
};

export {useLazyListSections};