import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import idx from 'idx';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  CircularProgress
} from '@material-ui/core';

import * as cs from '../../../constants/theme';
import InternalIcon from '@material-ui/icons/Lock';
import UpdateIcon from '@material-ui/icons/Create';
import ShareIcon from '@material-ui/icons/People';
import RequestIcon from '@material-ui/icons/Input';
import WarningIcon from '@material-ui/icons/WarningRounded';

import { RepeatingProjectDropdown } from './RepeatingProjectDropdown';
import NameLabel from '../../common/NameLabel';
import OwnerForm from '../../common/InviteForm';
import CompanyForm from './CompanyForm';
import Dropdown from '../../common/Dropdown';
import { DatePicker } from '../../common/DatePicker';
import { CustomRecurringProjects } from './CustomRecurringProjects';

import { useDispatchContext, useStateContext } from '../../../store';
import { RECON_TYPES } from '../../../constants/types';

import {
  SearchCompanyUsers_searchCompanyUsers_users,
  SearchCompanyUsers_searchCompanyUsers_teams
} from '../../../helpers/__generated__/SearchCompanyUsers';
import useRetentionPolicies from '../../../graphql/queries/RetentionPolicies';
import { slice } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    warning: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      backgroundColor: 'rgba(248,231,28,0.20)',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      marginTop: '12px',
      padding: '12px 20px'
    },
    none: { visibility: 'hidden' },
    pencil: { marginRight: '10px' },
    body: {
      display: 'flex',
      height: 'calc(100vh - 144px)',
      padding: '0px calc((100% - 1080px) / 2) 0px calc((100% - 1080px) / 2)',
      borderBottom: '1px solid #D8D8D8',
      overflow: 'auto'
    },
    pane: {
      width: '500px',
      padding: '20px'
    },
    footer: {
      height: '60px',
      padding: '0px calc((100% - 1080px) / 2) 0px calc((100% - 1080px) / 2)'
    },
    flex: {
      display: 'flex',
      alignItems: 'center'
    },
    secondaryText: {
      color: '#606060',
      marginTop: 8,
      marginBottom: 6
    },
    grow: {
      flexGrow: 1
    },
    secondary: {
      color: '#606060',
      marginTop: '24px',
      marginBottom: '12px'
    },
    ownerLabel: {
      height: '36px'
    },
    input: {
      marginTop: '16px',
      width: '100%',
      color: '#000000',
      lineHeight: '24px',
      wordBreak: 'break-all',
      letterSpacing: '0em',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.sm,
      fontWeight: cs.FONT.weight.regular,
      outline: 'none',
      border: 'none'
    },
    sharingTitle: {
      color: '#606060',
      marginLeft: '3px'
    },
    icon: {
      fontSize: '15px',
      marginRight: '12px'
    },
    explanation: {
      color: '#606060',
      marginLeft: '32px'
    },
    grayRect: {
      width: '36px',
      height: '36px',
      backgroundColor: '#F2F2F2',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      marginRight: '12px'
    },
    description: {
      width: '100%',
      height: '180px',
      borderRadius: '3px',
      resize: 'none',
      border: '1px solid #D8D8D8',
      color: '#2C2C2C',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 600
    },
    showMoreButton: {
      color: '#606060',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      fontSize: '12px',
      marginBottom: '0px',
      zIndex: 1,
      height: '26px',
      minWidth: '16px',
      '& span': {
        marginTop: '1px'
      },
      '&:hover': {
        border: '1px solid #3A84FF;'
      }
    }
  })
);

interface NewCompanyType {
  newCompanyName: string;
  ownerEmail: string;
}

function CreateListStep(props: any) {
  const {
    newTemplate,
    setNewTemplate,
    selectedTemplate,
    onSubmit,
    createListResponse,
    history,
    hideRecons,
    isProcessing
  } = props;
  const classes = useStyles();

  const dispatch = useDispatchContext();
  const state = useStateContext();
  const adminPlus = state.currentUser.isAdmin;
  const [
    customRecurringProjectsModal,
    setCustomRecurringProjectsModal
  ] = useState<boolean>(false);
  const [policies, setPolicies] = useState([]);

  const [sharing, setSharing] = useState<string>('internal');
  const [inviteCompany, setInviteCompany] = useState<NewCompanyType>({
    newCompanyName: '',
    ownerEmail: ''
  });
  const [openAddPanel, setOpenAddPanel] = useState<boolean>(false);
  const [openAddPanelExternal, setOpenAddPanelExternal] = useState<boolean>(false);

  const createLoading = idx(createListResponse, r => r.loading);
  const createSuccess = idx(
    createListResponse,
    r => (r as any).data.createList.success
  );
  const copySuccess = idx(
    createListResponse,
    r => (r as any).data.copyList.success
  );

  useEffect(() => {
    if (createSuccess || copySuccess) {
      // Update the user lists for the project selection dropdown
      const userListsCopy = state.userLists.slice();
      const newList = Object.assign({}, createListResponse?.data?.copyList?.list as any);
      userListsCopy.push(newList);

      dispatch({
        type: 'SET_USER_LISTS',
        userLists: userListsCopy
      })

      dispatch({
        type: 'SET_NOTIFICATION',
        notification: {
          variant: 'success',
          message: 'Project created successfully'
        }
      });
      setTimeout(() => history.push('/app/lists'), 250);
    }
  }, [createListResponse?.called, createListResponse?.loading, createListResponse?.data]);

  /* show modal if 'Custom' is selected from the recurring projects dropdown */
  useEffect(() => {
    if (newTemplate.scheduleType === '6') setCustomRecurringProjectsModal(true);
  }, [newTemplate.scheduleType]);

  // As we only use retention policies on this page, makes sense to load them
  // in here //////////////////  Extract to own
  /// ////////////////////////////////////// component
  const {
    loading: policyLoading,
    error: policyError,
    data: policyData
  } = useRetentionPolicies({ companyId: state.currentUser.lastViewedCompanyId });

  useEffect(() => {
    if (!policyData) return;

    var pols = (policyData as any).retentionPolicies;
    if (!pols) return;

    setPolicies(pols);
    var defaultPolicy = pols.find((rp: any) => rp.isDefault);
    if (defaultPolicy) {
      setNewTemplate({ ...newTemplate, retentionPolicyId: defaultPolicy.id });
    }
  }, [policyData]);

  useEffect(() => {
    if (!hideRecons) return;
    
    setNewTemplate({ ...newTemplate, reconciliations: selectedTemplate.reconciliations });

  }, [hideRecons]);
  /// //////////////////////////////////////////////////////////////////////////

  /* this is a shim for now */
  const owners = newTemplate.userCompanyOwners;
  const setOwners = (
    o: React.SetStateAction<
      (
        | SearchCompanyUsers_searchCompanyUsers_users
        | SearchCompanyUsers_searchCompanyUsers_teams
      )[]
    >
  ) => {
    setNewTemplate({
      ...newTemplate,
      userCompanyOwners: o
    });
  };

  const setOtherCompanyOwners = (
    o: React.SetStateAction<
      (
        | SearchCompanyUsers_searchCompanyUsers_users
        | SearchCompanyUsers_searchCompanyUsers_teams
      )[]
    >
  ) => {
    setNewTemplate({
      ...newTemplate,
      otherCompanyOwners: o
    });
  };

  const handleChangeName = (e: any) => {
    setNewTemplate({
      ...newTemplate,
      name: e.target.value
    });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewTemplate({
      ...newTemplate,
      description: event.target.value
    });
  };

  const handleChangeSharing = (event: React.ChangeEvent<{}>, value: string) => {
    setSharing(value);

    if (value === 'issue') {
      setNewTemplate({
        ...newTemplate,
        requester: { id: state.selectedCompany }
      });
    } else if (value === 'share') {
      setNewTemplate({
        ...newTemplate,
        responder: { id: state.selectedCompany }
      });
    } else {
      setNewTemplate({
        ...newTemplate,
        requester: { id: state.selectedCompany },
        responder: null
      });
    }
  };

  const handleSubmit = () => {
    if (
      sharing === 'issue' &&
      (!newTemplate.responder ||
        newTemplate.responder.id === state.selectedCompany)
    ) {
      dispatch({
        type: 'SET_NOTIFICATION',
        notification: {
          variant: 'error',
          message: 'Must select an external company'
        }
      });
    } else if (
      sharing === 'share' &&
      (!newTemplate.requester ||
        newTemplate.requester.id === state.selectedCompany)
    ) {
      dispatch({
        type: 'SET_NOTIFICATION',
        notification: {
          variant: 'error',
          message: 'Must select an external company'
        }
      });
    } else {
      onSubmit();
    }
  };

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setNewTemplate({ ...newTemplate, estimatedEndDate: date });
    }
  };

  const handlePolicyUpdate = (option: any) => {
    setNewTemplate({ ...newTemplate, retentionPolicyId: option });
  };

  const handleRemoveOwnerClick = (removedOwner: any) => {
    if (owners.length <= 1) return; // a list must have at least 1 owner

    // if removed Owner shares id && typename, take it out
    var newOwners = owners.filter((owner: any) => {
      if (owner.__typename === removedOwner.__typename) {
        return owner.email !== removedOwner.email;
      } else {
        return true;
      }
    });

    setOwners(newOwners);
  };

  const handleRemoveOtherCompanyOwnerClick = (removedOwner: any) => {
    const owners = newTemplate.otherCompanyOwners;
    if (owners.length <= 1) return; // a list must have at least 1 owner

    // if removed Owner shares id && typename, take it out
    var newOwners = owners.filter((owner: any) => {
      if (owner.__typename === removedOwner.__typename) {
        return owner.email !== removedOwner.email;
      } else {
        return true;
      }
    });

    setOtherCompanyOwners(newOwners);
  };

  const InternalLabel = (
    <Typography
      className={classes.flex}
      variant="h6"
    >
      <InternalIcon className={classes.icon} />
      Internal
    </Typography>
  );
  const ShareLabel = (
    <Typography
      className={classes.flex}
      variant="h6"
    >
      <ShareIcon className={classes.icon} />
      Share
    </Typography>
  );
  const RequestLabel = (
    <Typography
      className={classes.flex}
      variant="h6"
    >
      <RequestIcon className={classes.icon} />
      Request
    </Typography>
  );

  const handleCalendarUpdate = (e: any) => {
    setNewTemplate({ ...newTemplate, scheduleType: e.target.value as number });
  };

  const updateCustomRecurringProject = (p: any) => {
    setNewTemplate({ ...newTemplate, customSchedule: p });
    setCustomRecurringProjectsModal(false);
  };

  const showWarning = false;

  /* ALOT GOING ON HERE */
  const externalCompanyId =
    idx(newTemplate, n => {
      if (sharing == 'issue') {
        return n.responder.id;
      } else {
        return n.requester.id;
      }
    }) || 'a';

  const handleReconChange = (v: any) => {
    props.updateListRecon('type', v);
  };

  return (
    <div>
      {customRecurringProjectsModal && (
        <CustomRecurringProjects
          onAccept={updateCustomRecurringProject}
          onClose={() => setCustomRecurringProjectsModal(false)}
          options={{ ...selectedTemplate.schedule }}
        />
      )}
      <div className={classes.body}>
        <div className={classes.pane}>
          <Typography variant="h2">
            {props.submitTitle}
          </Typography>
          <FormControl
            component="fieldset"
            style={{ marginTop: '24px' }}
          >
            <Typography
              className={classes.sharingTitle}
              variant="h6"
            >
              Sharing
            </Typography>
            <RadioGroup
              aria-label="sharing"
              name="sharing"
              onChange={handleChangeSharing}
              value={sharing}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                data-cy="internal-radio"
                label={InternalLabel}
                value="internal"
              />
              <Typography
                className={classes.explanation}
                variant="h6"
              >
                Collaborate within your company. Use this setting when sharing
                information with your colleagues
              </Typography>

              {adminPlus && (
                <>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    data-cy="share-radio"
                    label={ShareLabel}
                    value="share"
                  />
                  <Typography
                    className={classes.explanation}
                    variant="h6"
                  >
                    Send information to an external company. Use this setting
                    when your company has the primary responsibility for
                    providing the information in the task list.
                  </Typography>
                </>
              )}
              <FormControlLabel
                control={<Radio color="primary" />}
                data-cy="issue-radio"
                label={RequestLabel}
                value="issue"
              />
              <Typography
                className={classes.explanation}
                variant="h6"
              >
                Issue a request for information from an external company. Use
                this setting when the other company is responsible for providing
                most of the information in the task list.
              </Typography>
            </RadioGroup>
          </FormControl>
          {sharing !== 'internal' && (
            <div className={classes.warning}>
              <WarningIcon />
              <Typography
                style={{ marginLeft: '12px' }}
                variant="h6"
              >
                This list and all of its contents will be public to the selected
                company upon creation.
              </Typography>
            </div>
          )}
        </div>
        <div
          className={classes.pane}
          data-cy="project-name-container"
        >
          <Typography variant="h2">
            Sharing to Project Type
          </Typography>
          <FormControl style={{ marginTop: 5, width: '100%' }}>
            <Input
              onChange={handleChangeName}
              value={newTemplate.name}
            />
          </FormControl>
          <div>
            <Typography
              className={classes.secondary}
              variant="h6"
            >
              Template
            </Typography>
            <div className={classes.flex}>
              <div className={classes.grayRect} />
              <Typography variant="h6">
                {selectedTemplate.name}
              </Typography>
            </div>
          </div>

          <div>
            <Typography
              className={classes.secondary}
              variant="h6"
            >
              Project owner(s)
            </Typography>
            <div className={classes.flex}>
              <RenderOwnersRow
                handleRemoveOwnerClick={handleRemoveOwnerClick}
                owners={owners}
              />
              <OwnerForm
                adminPlus={adminPlus}
                owners={owners}
                setOwners={setOwners}
                openAddPanel={openAddPanel}
                setOpenAddPanel={setOpenAddPanel}
              />
            </div>
          </div>
          {showWarning && (sharing == 'share' || sharing == 'issue') && (
            <Typography
              style={{ color: 'red', fontWeight: 500, margin: 0 }}
              variant="h6"
            >
              *You must issue this project to a company if you have selected
              Share or Request.
            </Typography>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              width: 650
            }}
          >
            {sharing !== 'internal' && (
              <CompanyForm
                inviteCompany={inviteCompany}
                newTemplate={newTemplate}
                setInviteCompany={setInviteCompany}
                setNewTemplate={setNewTemplate}
                sharing={sharing}
                showWarning={showWarning}
              />
            )}
            {idx(newTemplate, n => n.otherCompanyOwners.length > 0) && (
              <div
                style={{ display: 'flex', flexDirection: 'column', width: 450 }}
              >
                <div style={{ marginTop: 24 }}>
                  <Typography
                    style={{ color: '#606060', marginBottom: 12 }}
                    variant="h6"
                  >
                    External Users
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <RenderOwnersRow
                    handleRemoveOwnerClick={handleRemoveOtherCompanyOwnerClick}
                    owners={newTemplate.otherCompanyOwners}
                  />
                  <OwnerForm
                    adminPlus
                    externalCompanyId={externalCompanyId}
                    owners={newTemplate.otherCompanyOwners}
                    setOwners={setOtherCompanyOwners}
                    openAddPanel={openAddPanelExternal}
                    setOpenAddPanel={setOpenAddPanelExternal}
                    whatTheHell
                  />
                </div>
              </div>
            )}
          </div>

          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '40%', display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                className={classes.secondaryText}
                variant="h6"
              >
                Project End Date
              </Typography>
              <DatePicker
                onChange={handleChangeDate}
                value={newTemplate.estimatedEndDate}
              />
            </div>
            <div
              style={{
                width: '40%',
                display: 'flex',
                alignItems: 'flex-end',
                marginLeft: 14
              }}
            >
              <RepeatingProjectDropdown
                data-cy="repeating-dropdown"
                onChange={handleCalendarUpdate}
                value={newTemplate.scheduleType}
              />
            </div>
          </div>

          { !hideRecons &&
            <div style={{ display: 'flex', marginTop: 7, alignItems: 'flex-end' }}>
              <div style={{ width: '40%', marginRight: 20 }}>
                <Typography
                  className={classes.secondaryText}
                  variant="h6"
                >
                  Reconciliations
                </Typography>

                <Dropdown
                  handleUpdate={handleReconChange}
                  options={RECON_TYPES}
                  placeholder="Not Active"
                  selected={newTemplate.reconciliations || '0'}
                  small
                />
              </div>
              <div>
                <Button onClick={() => props.setStep(3)}>
                  Reconciliation Settings
                </Button>
              </div>
            </div>}

          <div style={{ display: 'flex', marginTop: 7, alignItems: 'center' }}>
            <div style={{ width: '40%', marginRight: 20 }}>
              <Typography
                className={classes.secondaryText}
                variant="h6"
              >
                Retention Policy
              </Typography>
              <Dropdown
                handleUpdate={handlePolicyUpdate}
                options={policies}
                placeholder="Select a Policy"
                selected={newTemplate.retentionPolicyId as string}
                small
              />
            </div>
          </div>
          <div>
            <Typography
              className={classes.secondary}
              variant="h6"
            >
              Description
            </Typography>
            <textarea
              className={classes.description}
              data-cy="new-project-desc"
              onChange={handleChangeDescription}
              value={newTemplate.description}
            />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div
          className={classes.flex}
          style={{ paddingTop: '12px' }}
        >
          <div className={classes.grow} />
          <Button
            data-cy="submit-button"
            disabled={createLoading as boolean || isProcessing}
            onClick={handleSubmit}
            variant="contained"
          >
            {(createLoading || isProcessing) && <CircularProgress style={{ height: '15px', width: '15px', color: '#606060', marginRight: '10px' }} />}
            {props.submitTitle}
          </Button>
        </div>
      </div>
    </div>
  );
}

function Input(props: any) {
  const [hover, setHover] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <div
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      style={{
        display: 'flex',
        borderBottom: '1px solid #D8D8D8',
        alignItems: 'flex-end'
      }}
    >
      <input
        className={classes.input}
        data-cy="name-input"
        onChange={props.onChange}
        value={props.value}
      />
      <UpdateIcon
        className={clsx(hover ? null : classes.none, classes.pencil)}
      />
    </div>
  );
}

export default withRouter(CreateListStep);

interface RenderOwnersRowProps {
  owners: any;
  handleRemoveOwnerClick: any;
  truncate?: number;
}

export function RenderOwnersRow(props: RenderOwnersRowProps) {
  const classes = useStyles();
  const { truncate, owners, handleRemoveOwnerClick } = props;

  const isOverSize = truncate ? owners.length > truncate : false;
  const [showMore, setShowMore] = useState(isOverSize);
  const renderOwners = truncate
    ? !showMore
      ? owners
      : slice(owners, 0, truncate)
    : owners;

  return (
    <>
      {renderOwners &&
        renderOwners.map(
          (
            owner:
              | any
              | SearchCompanyUsers_searchCompanyUsers_users
              | SearchCompanyUsers_searchCompanyUsers_teams,
            index: number
          ) => {
            return owner.__typename === 'User' ? (
              <NameLabel
                className={classes.ownerLabel}
                key={index}
                label={owner.fullName || owner.email}
                labelStyle={{ whiteSpace: 'nowrap' }}
                logo={owner.profileUrl as string}
                onClose={() => handleRemoveOwnerClick(owner)}
                truncate={25}
                type="user"
              />
            ) : (
              <NameLabel
                className={classes.ownerLabel}
                key={index}
                label={owner.name || owner.email}
                labelStyle={{ whiteSpace: 'nowrap' }}
                onClose={() => handleRemoveOwnerClick(owner)}
                truncate={25}
                type="user"
              />
            );
          }
        )}
      {!!truncate && isOverSize && (
        <div style={{ display: 'flex', alignItems: 'center', height: 36 }}>
          {showMore ? (
            <Button
              className={classes.showMoreButton}
              onClick={() => {
                setShowMore(prev => !prev);
              }}
            >
              +
              {' '}
              {owners.length - truncate}
            </Button>
          ) : (
            <Button
              className={classes.showMoreButton}
              onClick={() => {
                setShowMore(prev => !prev);
              }}
            >
              -
            </Button>
          )}
        </div>
      )}
    </>
  );
}
