import { useLazyQuery } from '@apollo/client';
import { createQueryHook, gql } from '../graphqlHelpers';
import { UserLists } from './__generated__/UserLists';

export const useUserLists = createQueryHook<UserLists, {}>(gql`
  query UserLists {
    userLists {
      id
      lists {
        companyPosition
        deliverFileOnUpload
        currentUserIsOwner
        isArchived
        isDeleted
        id
        name
        description
        isVolatile
        requesterCompany {
          id
          name
        }
        owners {
          ... on User {
				    id
            email
            fullName
            profileUrl
				  }
				  ... on Team {
				    id
				    name
				  }
        }
        schedule {
          id
          repeatType
        }
        reconciliations
        taggingUsers {
          ... on User {
				    id
            email
            fullName
            profileUrl
				  }
				  ... on Team {
				    id
				    name
				  }
			  }
        sections {
          id
          name
          position
        }
        labels {
          id
          name
          color
        }
      }
    }
  }
`);

// Prior to adding any additional attributes to this specific query, ensure that the performance remains unaffected.
// The primary objective of this query is to maximize speed when loading lists for the dropdown.
export const listsLight = gql`
  query UserListsLight {
    userLists {
      id
      lists {
        id
        name
        description
      }
    }
  }
`

export const useUserListsLight = createQueryHook<UserLists, {}>(listsLight);


export const useLazyUserListsLight = () => {
  return useLazyQuery(listsLight);
}
