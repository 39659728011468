import { useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../graphqlHelpers';
import { DetailedListInfo, DetailedListInfoVariables } from './__generated__/DetailedListInfo';
import {
    ListMessagesQuery,
    ListMessagesQueryVariables,
    ListFilesQuery,
    ListFilesQueryVariables,
    ListNotificationsQuery,
    ListNotificationsQueryVariables,
} from './__generated__/DetailedListInfo';

// Query that only fetch the list of the availables files for a given list with `id`.
const listFilesQuery = gql `
    query ListFilesQuery($id: ID!) {
        list(id: $id) {
            id
            files {
                id
                fileName
                fileLocation
                alterable
                createdAt
                updatedAt
                taskId
                viewed
                isCheckedOut
                checkedOutBy {
                    fullName
                }
            }
        }
    }
`

// Query that only fetch the list of the availables messages for a given list with `id`.
const listMessagesQuery = gql `
query ListMessagesQuery($id: ID!, $cursor: String) {
    list(id: $id) {
        id
        messages(first: 30, after: $cursor) {
            edges {
                cursor
                node {
                    id
                    createdAt
                    message
                    isPublic
                    viewed
                    task {
                        id
                        name
                        listNumber
                    }
                    user {
                        fullName
                        profileUrl
                    }
                }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
        }
    }
}
`


// Query that only fetch the list of the availables messages for a given list with `id`.
const listNotificationsQuery = gql `
    query ListNotificationsQuery($id: ID!) {
        list(id: $id) {
            id
            tasks {
                id
                notifications {
                    id
                    name
                    description
                    url
                    actionType
                    createdAt
                    isAutomation
                    readAt
                    notifiableId
                    notifiableType
                    pane
                    notifiable {
                        ...on Task {
                            listId
                        }
                    }
                }
            }
        }
    }
`

const detailedListInfoQuery = gql`
  query DetailedListInfo($id: ID!) {
    list(id: $id) {
        id
        name
        rank
        reconciliations
        modifiedAt
        currentUserIsOwner
        reconEndDate
        isDeleted
        isRetained
        isArchived
        retentionEnd
        isPastRetention
        isPastEndDate
        totalTasks
        totalValidTasks
        completeTasks
        companyPosition
        sections {
            id
            name
            position
        }
        requesterCompany {
            id
            name
            logoUrl
        }
        responderCompany {
            id
            name
            logoUrl
        }
        sharedCompany {
            id
            name
            logoUrl
        }
        multipleReviewers
        tasks {
            id
            status
            completedStatusKey
            priority
            reconciliations
            isReconciled
            responderDelivered
            companyPosition
        }
        description
        deliverFileOnUpload
        schedule {
            id
            repeatType
            repeatNumber
            repeatInterval
            calendarType
            ends
            endsOn
            endsAfter
            selectedWeekday
            selectedMonthday
            selectedMonth
        }
        rollForwardSetting {
            copyRoles
            copyFiles
            copyInternalMessages
            copyExternalMessages
            copyDueDates
            copyStatuses
            copyProcessSteps
            copySchedules
            hideTask
        }
        owners {
            ... on User {
                id
                displayName
                profileUrl
                email
            }
            ... on Team {
                id
                name
    #			    profileUrl
                users {
                    id
                }
            }
        }
        externalOwners {
            ... on User {
                id
                displayName
                profileUrl
                fullName
                email
                roles {
                    name
                }
            }
            ... on Team {
                id
                name
                companyId
                users {
                    id
                }
            }
        }
        retentionPolicy {
            id
            name
            duration
        }
        estimatedEndDate
        estimatedStartDate
        taggingUsers {
            ... on User {
                id
                displayName
            }
            ... on Team {
                id
                name
            }
        }
        labels {
            id
            name
            color
        }
        useShortSubTaskStatuses
    }
}
`

// This query is utilized for creating a detailed list within the context of importing tasks templates.
// Before making any updates to this query, please ensure that the performance impact has been thoroughly evaluated and confirmed to be minimal.
const detailedListLightInfoQuery = gql`
    query DetailedListInfo($id: ID!) {
        list(id: $id) {
            id
            name
            description
            isVolatile
            reconciliations
            requesterCompany {
                id
                name
            }
            schedule {
                id
                repeatType
            }
            owners {
                ... on User {
                    id
                    displayName
                    profileUrl
                    email
                }
                ... on Team {
                    id
                    name
                }
            }
        }
    }
`

const useDetailedListInfo = (variables: any) => {
    return useQuery<DetailedListInfo, DetailedListInfoVariables>(detailedListInfoQuery, {variables: variables});
}
const useLazyDetailedListLightInfo = (variables: any) => {
    return useLazyQuery<DetailedListInfo, DetailedListInfoVariables>(detailedListLightInfoQuery, {variables: variables});
}
const useLazyDetailedListInfo = (variables: any) => {
    return useLazyQuery<DetailedListInfo, DetailedListInfoVariables>(detailedListInfoQuery, {variables: variables})
}

const useListMessagesQuery = (variables: any) => {
    return useLazyQuery<ListMessagesQuery, ListMessagesQueryVariables>(listMessagesQuery, { variables: variables});
}

const useListFilesQuery = (variables: any) => {
    return useLazyQuery<ListFilesQuery, ListFilesQueryVariables>(listFilesQuery, { variables: variables });
}

const useListNotificationsQuery = (variables: any) => {
    return useLazyQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(listNotificationsQuery, { variables: variables });
}

export { useDetailedListInfo, useLazyDetailedListInfo, useListMessagesQuery, useListFilesQuery, useListNotificationsQuery, useLazyDetailedListLightInfo };
