import { useEffect, useState } from 'react';
import {gql, useMutation} from '@apollo/client';

const mutation = gql`
  mutation UpdateTaskRecon(
    $recon: ReconSourceAttributes!
  ) {
    updateTaskReconSources(
      recon: $recon
    ) {
      task {
        currency
        taskDataSummary {
          id
          primaryReconId
          primaryReconName
          primaryReconValue
          primarySourceType
          secondaryReconId
          secondaryReconName
          secondaryReconValue
          secondarySourceType
          primarySecondaryDifferenceValue
          primarySecondaryDifferencePercentage
          reconcilingItemId
          reconcilingItemName
          reconcilingItemValue
          reconcilingDifferenceValue
          reconcilingDifferencePercentage
          priorMonthId
          priorMonthValue
          priorMonthDifference
          priorMonthPercentage
          priorQuarterId
          priorQuarterValue
          priorQuarterDifference
          priorQuarterPercentage
          priorYearId
          priorYearValue
          priorYearDifference
          priorYearPercentage
          customAnalysisId
          customAnalysisValue
          customAnalysisDifference
          customAnalysisPercentage
          finalRecon
          isCurrent
          companyId
          listId
          taskId
          sectionId
          createdAt
          updatedAt
        }
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        primarySource {
          id
          fileVersion {
            taskId
            id
            fileName
            fileLocation
            alterable
          }
          integrationInfo {
            id
            companyId
            locationId
            ledgerId
            transactionType 
            period 
            slStartDate
            slEndDate
    
            ###########################
            #intacct stuff
            ###########################
            inEntities {
              label
              value 
            }
            inDepartments {
              label
              value 
            }
            inUnderliers {
              label
              value 
            }
            inAccounts {
              label
              value 
            }
            inServices {
              label
              value 
            }
            inCustomers {
              label
              value 
            }
            inVendors {
              label
              value 
            }
            inAccountingMethod {
              label
              value 
            }
            inStartDate
            inEndDate
            invert
            includeReportingBook
            startDateLocked
            endDateLocked
    
            ###########################
            #qbo stuff
            ###########################
            qboLocations {
              label
              value 
            }
            qboAccounts {
              label
              value 
            }
            qboNames {
              label
              value 
            }
            qboClasses {
              label
              value 
            }
            qboTypes {
              label
              value 
            }
            qboAccountingMethod {
              label
              value 
            }
            qboStartDate
            qboEndDate
            qboIntegration {
              label
              value
            }
    
            ######################
            #ns stuff
            ######################
            nsAccounts {
              label
              value
            }
            nsSubsidiaries{
              label
              value
            }
            nsLocations {
              label
              value
            }
            nsDepartments {
              label
              value
            }
            nsClasses {
              label
              value
            }
            nsItems {
              label
              value
            }
            nsEntities {
              label
              value
            }
            nsBooks {
              label
              value
            }
            nsStartDate
            nsEndDate
            currency
          }
          value
        }
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        secondarySource {
          id
          fileVersion {
            taskId
            id
            fileName
            fileLocation
            alterable
          }
          integrationInfo {
            id
            companyId
            locationId
            ledgerId
            transactionType 
            period 
            slStartDate
            slEndDate
    
            ###########################
            #intacct stuff
            ###########################
            inEntities {
              label
              value 
            }
            inDepartments {
              label
              value 
            }
            inUnderliers {
              label
              value 
            }
            inAccounts {
              label
              value 
            }
            inServices {
              label
              value 
            }
            inCustomers {
              label
              value 
            }
            inVendors {
              label
              value 
            }
            inAccountingMethod {
              label
              value 
            }
            inStartDate
            inEndDate
            invert
            includeReportingBook
            startDateLocked
            endDateLocked
    
            ###########################
            #qbo stuff
            ###########################
            qboLocations {
              label
              value 
            }
            qboAccounts {
              label
              value 
            }
            qboNames {
              label
              value 
            }
            qboClasses {
              label
              value 
            }
            qboTypes {
              label
              value 
            }
            qboAccountingMethod {
              label
              value 
            }
            qboStartDate
            qboEndDate
            qboIntegration {
              label
              value
            }
    
            ######################
            #ns stuff
            ######################
            nsAccounts {
              label
              value
            }
            nsSubsidiaries{
              label
              value
            }
            nsLocations {
              label
              value
            }
            nsDepartments {
              label
              value
            }
            nsClasses {
              label
              value
            }
            nsItems {
              label
              value
            }
            nsEntities {
              label
              value
            }
            nsBooks {
              label
              value
            }
            nsStartDate
            nsEndDate
            currency
          }
          value
        }
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
        reconcilingItems {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        files {
          id
          fileName
          fileLocation
          alterable
          viewed
          updatedAt
          taskId
          createdAt
          isCheckedOut
          checkedOutBy{
            fullName
          }
          checkedOutAt
          companyId
        }
      }
      success
      errors {
        message
        path
      }
    }
  }
`;

export function useUpdateTaskRecon() {
  const [recon, setRecon] = useState<any>(null);
  const [ updateTaskRecon, response ] = useMutation(mutation);

  useEffect(() => {
    if ( recon && recon.taskId ) {
      updateTaskRecon({variables: {recon}});
    }
  }, [recon])

  useEffect(() => {
    if (response.data && recon && recon.responseCallback) {
      recon.responseCallback(response.data, recon.sourceType);
    }
  }, [response.called, response.loading, response.data])

  return function(recon: any) {
    setRecon(recon);
  }
}
